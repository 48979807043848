import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const downloadProgressReport = (
  users: number[],
  cohort_id: string,
  cursusName: string,
  language: string,
  setDownloadingProgressReport: Function,
  successCallback?: Function
) => {
  setDownloadingProgressReport(true);
  ApiService.postDownload(`cohorts/${cohort_id}/users/progress/batch`, {
    cursus_name: cursusName,
    user_ids: users,
  })
    .then((resp) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([resp.data], { type: "application/zip" }));
      link.setAttribute("download", `${cohort_id}_progress_report.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      successCallback?.();
    })
    .catch((err) => {
      console.error(err);
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.ALERTS.ERROR"));
    })
    .finally(() => setDownloadingProgressReport(false));
};
