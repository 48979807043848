import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { Animator, Organizer } from "./Meeting.d";

export const fetchMeeting = (meetingId: string, language: string, setMeeting: Function, setLoaded: Function) => {
  setLoaded(false);
  ApiService.get(`meetings/${meetingId}`)
    .then((response) => {
      setMeeting(response.data.meeting);
    })
    .catch((err) => {
      console.error("Error fetching meeting data:", err);
    })
    .finally(() => setLoaded(true));
};

export const updateMeeting = (
  meetingId: string,
  meetingData: any,
  language: string,
  setShowEditModal: Function,
  setIsLoading: Function
) => {
  setIsLoading(true);
  ApiService.put(`meetings/${meetingId}`, meetingData)
    .then(() => {
      alert(translate(language, "PAGES.MEETING.SUCCESS.EDITED_MEETING"));
      setShowEditModal(false);
    })
    .catch((err) => {
      console.error("Error updating meeting:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.EDITED_MEETING"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const deleteMeeting = (meetingId: string, language: string, setIsLoading: Function, callback?: Function) => {
  setIsLoading(true);
  ApiService.delete(`meetings/${meetingId}`)
    .then((_) => {
      callback?.();
    })
    .catch((err) => {
      console.error("Error deleting meeting:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.DELETE_MEETING"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const getRessources = (
  setOrganizers: Function,
  setAnimators: Function,
  language: string,
  callback?: Function
) => {
  ApiService.get("meetings/resources")
    .then((response) => {
      const organizersWithId = response.data.meeting_staff_and_programs.organizers.map(
        (organizer: Organizer, index: number) => ({
          ...organizer,
          id: index,
        })
      );
      const animatorsWithId = response.data.meeting_staff_and_programs.animators.map(
        (animator: Animator, index: number) => ({
          ...animator,
          id: index,
        })
      );
      setOrganizers(organizersWithId);
      setAnimators(animatorsWithId);
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to get animators and organizers:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.GET_ADMINS"));
    });
};

export const updateAnimators = (
  meetingId: string,
  updatedAnimators: { username: string; display: string; first_name: string; last_name: string }[],
  setSelectedAnimators: Function,
  generateZoomLink: boolean,
  language: string,
  setIsLoading: Function
) => {
  setIsLoading(true);
  ApiService.put(`meetings/${meetingId}/animators`, {
    animators_email: updatedAnimators.map((animator) => animator.username),
    generate_zoom_link: generateZoomLink,
  })
    .then(() => {
      setSelectedAnimators(
        updatedAnimators.map((animator) => ({
          username: animator.username,
          display: animator.display || `${animator.first_name} ${animator.last_name}`,
        }))
      );
      alert(translate(language, "PAGES.MEETING.SUCCESS.UPDATED_ANIMATOR"));
    })
    .catch((error) => {
      console.error("Error updating animators:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.UPDATED_ANIMATOR"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};
