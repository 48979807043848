import React, { useEffect, useMemo, useState } from "react";
import { Placeholder, Table } from "react-bootstrap";

import ApiService from "../../services/ApiService";
import { getNestedProperty } from "../../services/UtilityService";
import DstButton from "../DstButton/DstButton";
import ChevronsUpDown from "../DstIcons/ChevronsUpDown";
import { DstLiveTableProps } from "./DstTable.d";
import "./DstTable.scss";

const _prepareHeader = (input: Array<any>) => {
  let result: any[] = [];

  input.forEach((element: any) => {
    if (element.key) {
      result[0] = [...(result[0] ?? []), element];
    } else if (element.children) {
      result[0] = [
        ...(result[0] ?? []),
        {
          name: element.name,
          size: element.children.length,
        },
      ];
      result[1] = [...(result[1] ?? []), element.children].flat();
    }
  });
  return result;
};

const _requestSort = (chosenKey: string, setSortOrder: Function, setSortKey: Function) => {
  setSortKey((previouslyChosenKey: string) => {
    if (previouslyChosenKey === chosenKey) {
      setSortOrder((previousOrder: string) => (previousOrder === "asc" ? "desc" : "asc"));
      return previouslyChosenKey;
    } else {
      setSortOrder("desc");
      return chosenKey;
    }
  });
};

export const DstLiveTable = ({
  classLine,
  classTable,
  columns,
  itemsPerPage = 10,
  onRowClick,
  pagination = false,
  paginationAlwaysShowFooter = false,
  remote,
  remoteKey,
  remoteTransform,
  striped = false,
  stylishLine,
}: DstLiveTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
  const [loaded, setLoaded] = useState(false);
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationInfos, setPaginationInfos] = useState({
    current_page: 0,
    current_size: 0,
    total_pages: 0,
    total_records: 0,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [remote, sortKey, sortOrder]);

  useEffect(() => {
    setItemsLoaded(false);
    ApiService.get(
      `${remote}${remote.includes("?") ? "&" : "?"}order_by=${sortKey}&order=${sortOrder}&page=${
        currentPage - 1
      }&limit=${itemsPerPage}`
    )
      .then((resp) => {
        const paginatedReturn = resp.data[remoteKey];
        setItems(paginatedReturn.items);
        setPaginationInfos(paginatedReturn.pagination);
      })
      .catch((err) => {
        console.error(err);
        setItems([]);
      })
      .finally(() => {
        setLoaded(true);
        setItemsLoaded(true);
      });
  }, [currentPage, itemsPerPage, remote, remoteKey, sortKey, sortOrder]);

  const tableHeaders = useMemo(() => {
    return _prepareHeader(columns);
  }, [columns]);

  const pageButtons = useMemo(() => {
    const maxButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, paginationInfos.total_pages);

    if (endPage === paginationInfos.total_pages) {
      startPage = Math.max(paginationInfos.total_pages - maxButtons + 1, 1);
    }

    return Array.from({ length: Math.min(maxButtons, paginationInfos.total_pages) }, (_, i) => startPage + i);
  }, [currentPage, paginationInfos]);

  if (!loaded) {
    return (
      <React.Fragment>
        <Table striped={striped} bordered responsive className={`s-regular dst-table ${classTable || ""}`}>
          <thead>
            <tr>
              {tableHeaders.map((row: any, rowIndex: number) => (
                <React.Fragment key={`header-row-${rowIndex}`}>
                  {row.map((column: any, colIndex: number) => (
                    <th
                      key={`header-col-${rowIndex}-${colIndex}`}
                      rowSpan={column.size ? 1 : 2}
                      colSpan={column?.size ?? 1}
                      onClick={() => (column.sortable ? _requestSort(column.key, setSortOrder, setSortKey) : null)}
                      className="clickable align-content-center border-table"
                      style={column.stylishTitle ? column.stylishTitle() : {}}
                    >
                      {column.name} {column.sortable && <ChevronsUpDown />}
                    </th>
                  ))}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(itemsPerPage)].map((_elem, index) => (
              <Placeholder key={`mock-placeholder-${index}`} animation="glow" as="tr" className="list-row">
                {columns.map((column) => (
                  <td key={`mock-cell-${index}-${column.key}`}>
                    <Placeholder as="span" xs={12} />
                  </td>
                ))}
              </Placeholder>
            ))}
          </tbody>
        </Table>
        <div>
          {(paginationInfos.total_pages > 1 || paginationAlwaysShowFooter) &&
            pagination &&
            paginationInfos.total_records > 0 && (
              <div className="d-flex mw-100 justify-content-center align-items-center m-3">
                <DstButton btnClass="me-1" btnWidth="30px" value="" mock />
                <DstButton btnClass="me-1" btnWidth="30px" value="" mock />
                {pageButtons.map((page) => (
                  <DstButton btnClass="ms-1" btnWidth="30px" key={page} value="" mock />
                ))}
                <DstButton btnClass="ms-2" btnWidth="30px" value="" mock />
                <DstButton btnClass="ms-1" btnWidth="30px" value="" mock />
              </div>
            )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Table striped={striped} bordered responsive className="s-regular dst-table" hover={!!onRowClick}>
          <thead>
            <tr>
              {tableHeaders.map((row: any, rowIndex: number) => (
                <React.Fragment key={`header-row-${rowIndex}`}>
                  {row.map((column: any, colIndex: number) => (
                    <th
                      key={`header-col-${rowIndex}-${colIndex}`}
                      rowSpan={column.size ? 1 : 2}
                      colSpan={column?.size ?? 1}
                      onClick={() => (column.sortable ? _requestSort(column.key, setSortOrder, setSortKey) : null)}
                      className="clickable align-content-center border-table"
                      style={column.stylishTitle ? column.stylishTitle() : {}}
                    >
                      {column.name} {column.sortable && <ChevronsUpDown />}
                    </th>
                  ))}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {itemsLoaded
              ? items
                  .map((item: any) => (remoteTransform ? remoteTransform(item) : item))
                  .map((item, index) => (
                    <tr
                      key={`row-${index}`}
                      className={`${onRowClick ? "clickable" : ""} ${classLine?.(item) || ""}`}
                      onClick={(event) => onRowClick?.(event, item)}
                    >
                      {columns.map((column) => (
                        <td
                          key={column.key}
                          style={{ ...stylishLine?.(item), ...column?.stylishCell?.(item) }}
                          onClick={(event) => {
                            if (column?.onCellClick) {
                              event.stopPropagation();
                              column.onCellClick(event, item);
                            }
                          }}
                        >
                          {column.render ? column.render(item) : getNestedProperty(item, column.key)}
                        </td>
                      ))}
                    </tr>
                  ))
              : [...Array(itemsPerPage)].map((_elem, index) => (
                  <Placeholder key={`mock-placeholder-${index}`} animation="glow" as="tr" className="list-row">
                    {columns.map((column) => (
                      <td key={`mock-cell-${index}-${column.key}`}>
                        <Placeholder as="span" xs={12} />
                      </td>
                    ))}
                  </Placeholder>
                ))}
          </tbody>
        </Table>
        {(paginationInfos.total_pages > 1 || paginationAlwaysShowFooter) &&
          pagination &&
          paginationInfos.total_records > 0 && (
            <div className="pagination-controls d-flex justify-content-center align-items-center m-3">
              <React.Fragment>
                <DstButton
                  btnClass="button-lister dst-icon-chevron-left-double me-1"
                  clickFunction={() => setCurrentPage(1)}
                  value=""
                  disabled={currentPage === 1}
                />
                <DstButton
                  btnClass="button-lister dst-icon-chevron-left me-1"
                  value=""
                  disabled={currentPage < 4}
                  clickFunction={() => setCurrentPage(Math.max(currentPage - 5, 1))}
                />
                {pageButtons.map((page) => (
                  <DstButton
                    btnClass="button-lister ms-1"
                    key={page}
                    value={page.toString()}
                    variant={currentPage === page ? "primary" : "secondary"}
                    clickFunction={() => setCurrentPage(page)}
                  />
                ))}
                <DstButton
                  btnClass="button-lister dst-icon-chevron-right ms-2"
                  value=""
                  disabled={currentPage > paginationInfos.total_pages - 3}
                  clickFunction={() => setCurrentPage(Math.min(currentPage + 5, paginationInfos.total_pages))}
                />
                <DstButton
                  btnClass="button-lister dst-icon-chevron-right-double ms-1"
                  clickFunction={() => setCurrentPage(paginationInfos.total_pages)}
                  value=""
                  disabled={currentPage === paginationInfos.total_pages}
                />
                {itemsLoaded ? (
                  <p className="ms-3 mb-0 s-regular">
                    <span>
                      {(currentPage - 1) * itemsPerPage} -{" "}
                      {(currentPage - 1) * itemsPerPage + paginationInfos.current_size} /{" "}
                      {paginationInfos.total_records}
                    </span>
                  </p>
                ) : (
                  <Placeholder as="div" className="ms-3 row">
                    <Placeholder as="span" xs={3} />
                    /
                    <Placeholder as="span" xs={3} />
                  </Placeholder>
                )}
              </React.Fragment>
            </div>
          )}
      </React.Fragment>
    );
  }
};

export default DstLiveTable;
