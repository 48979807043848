import { useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { CohortUsersProgressReportPopupProps } from "./CohortUsersProgressReportPopup.d";
import { downloadProgressReport } from "./CohortUsersProgressReportPopup.function";

export const CohortUsersProgressReportPopup = ({
  cohortId,
  language,
  openPopup,
  setOpenPopup,
  userIds,
}: CohortUsersProgressReportPopupProps) => {
  const [downloadingProgressReport, setDownloadingProgressReport] = useState(false);
  const [cursusName, setCursusName] = useState<string>("");

  return (
    <Popup
      show={openPopup}
      onClose={() => setOpenPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.TITLE")}
    >
      <div className="mt-3 m-regular">
        <input
          type="text"
          className="form-control mb-3"
          placeholder={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.PLACEHOLDER")}
          value={cursusName}
          onChange={(e) => setCursusName(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <DstButton
          variant="primary"
          value={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.CONFIRM_BUTTON")}
          clickFunction={() => {
            downloadProgressReport(userIds, cohortId, cursusName, language, setDownloadingProgressReport, () => {
              setOpenPopup(false);
            });
          }}
          loading={downloadingProgressReport}
        />
      </div>
    </Popup>
  );
};

export default CohortUsersProgressReportPopup;
