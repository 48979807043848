import { useEffect, useState } from "react";
import React from "react";
import { Col, OverlayTrigger, Placeholder, Row, Spinner, Tooltip } from "react-bootstrap";

import CertifBlockOverview from "../../../../components/CertifBlockOverview/CertifBlockOverview";
import DstButton from "../../../../components/DstButton/DstButton";
import CheckIcon from "../../../../components/DstIcons/CheckIcon";
import CrossIcon from "../../../../components/DstIcons/CrossIcon";
import ExternalLink from "../../../../components/DstIcons/ExternalLink";
import LockIcon from "../../../../components/DstIcons/LockIcon";
import ThreeDots from "../../../../components/DstIcons/ThreeDots";
import ModuleBlock from "../../../../components/ModuleBlock/ModuleBlock";
import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import NoteListOverview from "../../../../components/NoteListOverview/NoteListOverview";
import { Note } from "../../../../models/Note";
import ApiService from "../../../../services/ApiService";
import { PermissionComponent } from "../../../../services/PermissionGuard";
import translate from "../../../../services/Translate";
import { fetchModules, normalizeString, scrollToAnchor, updateUserModules } from "./UserOverView.function";

const UserOverview = ({
  user_data,
  lessonsLoaded,
  notesLoaded,
  certifsLoaded,
  setLoaded,
  language,
  setUserData,
  setActiveKey,
  setActiveNote,
}: {
  user_data: any;
  lessonsLoaded: boolean;
  notesLoaded: boolean;
  certifsLoaded: boolean;
  language: string;
  setUserData: Function;
  setActiveKey: (key: string) => void;
  setActiveNote: (key: Note) => void;
  setLoaded: Function;
}): JSX.Element => {
  const [modules, setModules] = useState<any[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    fetchModules(setModules);
  }, []);

  const _getSprintStatusProperties = (sprint: any) => {
    const dtEnd = new Date(sprint.dt_end);
    const dtStart = new Date(sprint.dt_start);
    const today = new Date();

    let circleColor = "light-gray-background";
    let iconComponent = (
      <LockIcon color="var(--color-gray)" classes="position-absolute top-50 start-50 translate-middle" />
    );

    if (sprint.is_completed) {
      circleColor = "green-background";
      iconComponent = (
        <CheckIcon color="var(--color-white)" classes="position-absolute top-50 start-50 translate-middle" />
      );
    } else if (dtEnd < today) {
      circleColor = "red-background";
      iconComponent = (
        <CrossIcon color="var(--color-white)" classes="position-absolute top-50 start-50 translate-middle" />
      );
    } else if (dtStart < today && dtEnd >= today) {
      circleColor = "yellow-background";
      iconComponent = (
        <CheckIcon color="var(--color-white)" classes="position-absolute top-50 start-50 translate-middle" />
      );
    }

    return { circleColor, iconComponent };
  };

  const notes = user_data.user_notes;

  const _selectNote = (note: Note) => {
    setActiveKey("notes");
    setActiveNote(note);
  };

  return (
    <Row className="w-100">
      <Col md={9}>
        <div className="s-bold lightgray-font mb-3">{translate(language, "PAGES.USER.OVERVIEW.PROGRESS.TITLE")}</div>
        {lessonsLoaded ? (
          <React.Fragment>
            <div className="d-flex mx-0 mb-4">
              <div className="w-fit px-0 me-5">
                <div
                  onClick={() => {
                    scrollToAnchor("user-modules");
                  }}
                >
                  <div className="p-4 border border-light rounded-circle position-relative light-gray-background mt-2">
                    <ThreeDots color="gray" classes="position-absolute top-50 start-50 translate-middle" />
                  </div>
                  <div className="text-center">{translate(language, "PAGES.USER.OVERVIEW.PROGRESS.OTHER")}</div>
                </div>
              </div>
              {user_data.user_sprints?.length > 0 && (
                <div
                  className={`d-flex px-0 mx-0 mt-2 position-relative justify-content-${
                    user_data.user_sprints?.length > 1 ? "between" : "center"
                  }`}
                  style={{
                    minWidth: 100,
                    overflowX: "auto",
                  }}
                >
                  {user_data.user_sprints?.length > 1 && (
                    <hr
                      className="position-absolute darkgray-font custom-line"
                      style={{
                        width: `calc(${user_data.user_sprints.length} * 70px + ${
                          (user_data.user_sprints.length - 1) * 1.5
                        }rem - 40px)`,
                      }}
                    />
                  )}

                  {user_data.user_sprints.map((sprint: any, index: number) => {
                    const { circleColor, iconComponent } = _getSprintStatusProperties(sprint);

                    return (
                      <div className="w-fit text-center px-0 min-w-70 me-4" key={`user-sprint-${index}`}>
                        <div onClick={() => scrollToAnchor(normalizeString(sprint.name))} className="clickable">
                          <OverlayTrigger
                            overlay={<Tooltip id={`tooltip-user-sprint-${index}`}>{sprint.name}</Tooltip>}
                          >
                            <div>
                              <div
                                className={`p-4 border border-light rounded-circle w-fit position-relative mx-auto ${circleColor}`}
                              >
                                {iconComponent}
                                <div className={`status-circle ${circleColor}`}></div>
                              </div>
                              <div>
                                Sprint{" "}
                                {index + 1}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="d-flex mb-4">
            {[...Array(5)].map((_, index) => (
              <Placeholder key={`sprint-${index}`} animation="glow" className={index === 0 ? "me-5" : "mx-4"}>
                <Placeholder as="img" height={50} width={50} style={{ borderRadius: "50%" }} />
                <Placeholder xs={12} role="mockup" />
              </Placeholder>
            ))}
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-3" id="user-modules">
            {translate(language, "PAGES.USER.OVERVIEW.MODULE.TITLE")}
          </h3>
          <PermissionComponent neededPermission={[{ methods: ["PUT"], route: "/users/*/modules" }]}>
            <React.Fragment>
              {lessonsLoaded ? (
                <MultiSelectDropdown
                  label={
                    isAdding ? (
                      <React.Fragment>
                        <span className="pe-2">
                          {translate(language, "PAGES.USER.OVERVIEW.MODULE.ADDING_BUTTON_TITLE")}
                        </span>
                        <Spinner size="sm" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className="pe-2">
                          {translate(language, "PAGES.USER.OVERVIEW.MODULE.ADD_BUTTON_TITLE")}
                        </span>
                        <i className="dst-icon-plus-circle align-text-bottom"></i>
                      </React.Fragment>
                    )
                  }
                  items={modules}
                  disabled={isAdding}
                  displayItem={(item: any) => <div>{item.name}</div>}
                  badgeItemProperty="name"
                  toggleClass="btn-secondary"
                  clearOnConfirm
                  language={language}
                  hideCaret={true}
                  onConfirm={(selectedItems) => {
                    setIsAdding(true);
                    updateUserModules(
                      user_data.id,
                      [...user_data.user_modules.map((user_module: any) => user_module.module), ...selectedItems],
                      () => {
                        setIsAdding(false);
                        setLoaded((loaded: any) => ({
                          ...loaded,
                          lessons: false,
                        }));
                        ApiService.get(`users/${user_data.id}/lessons`)
                          .then((resp) => {
                            const user_lessons = resp.data.return;
                            setUserData((userData: any) => ({
                              ...userData,
                              user_sprints: user_lessons.sprints,
                              user_modules: user_lessons.user_modules,
                            }));
                          })
                          .catch((err) => console.error("An error occurred for fetching user's lessons", err))
                          .finally(() =>
                            setLoaded((loaded: any) => ({
                              ...loaded,
                              lessons: true,
                            }))
                          );
                      }
                    );
                  }}
                />
              ) : (
                <MultiSelectDropdown
                  badgeItemProperty=""
                  displayItem={(item: any) => <React.Fragment />}
                  items={[]}
                  label=""
                  language={language}
                  onConfirm={() => {}}
                  mock
                />
              )}
            </React.Fragment>
          </PermissionComponent>
        </div>
        {lessonsLoaded ? (
          <React.Fragment>
            {user_data.user_modules.map((module: any, index: number) => (
              <div key={`user-module-${index}`}>
                <ModuleBlock module={module} setLoaded={setLoaded} setUserData={setUserData} />
              </div>
            ))}
            {user_data.user_sprints.map((sprint: any, index: number) => (
              <React.Fragment key={`user-sprint-${index}`}>
                <hr />
                <h3 className="mb-3" id={normalizeString(sprint.name)}>
                  {translate(language, "PAGES.USER.OVERVIEW.MODULE.SPRINT")}: {sprint.name}
                </h3>
                {sprint.modules.map((module: any, sub_index: number) => (
                  <div key={`user-sprint-${index}-module-${sub_index}`}>
                    <ModuleBlock module={module} setLoaded={setLoaded} setUserData={setUserData} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          [...Array(3)].map((_, index) => <ModuleBlock key={index} mock={true} />)
        )}
      </Col>
      <Col md={3} className="ps-3 pt-3">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h3>{translate(language, "PAGES.USER.NOTE.TITLE")}</h3>
          <DstButton
            clickFunction={() => setActiveKey("notes")}
            btnClass="p-0"
            btnImageAfter={<ExternalLink color="#9C9C9C" classes="ms-2" />}
            color="#9C9C9C"
            value={`${translate(language, "PAGES.USER.NOTE.SEE_ALL")} (${notes?.length || 0})`}
            variant="transparent"
          />
        </div>
        {notesLoaded ? (
          notes && notes.length > 0 ? (
            <NoteListOverview
              notes={notes?.sort((first: Note, second: Note) => second.dt_created - first.dt_created)?.slice(0, 5)}
              language={language}
              selectNote={_selectNote}
              setActiveKey={setActiveKey}
            />
          ) : (
            <p>{translate(language, "PAGES.USER.NOTE.NO_NOTE")} </p>
          )
        ) : (
          <NoteListOverview mock={true} language={language} />
        )}

        <div className="d-flex justify-content-between align-items-center mb-1 mt-3">
          <h3>{translate(language, "PAGES.USER.CERTIFICATION.TITLE")}</h3>
          <DstButton
            clickFunction={() => setActiveKey("certif-exams")}
            btnClass="p-0"
            btnImageAfter={<ExternalLink color="#9C9C9C" classes="ms-2" />}
            color="#9C9C9C"
            value={`${translate(language, "PAGES.USER.CERTIFICATION.SEE_ALL")} (${
              user_data?.user_certifs?.length || 0
            })`}
            variant="transparent"
          />
        </div>
        <div className="certif-container">
          {certifsLoaded ? (
            user_data.user_certifs && user_data.user_certifs.length > 0 ? (
              user_data.user_certifs
                .slice(-5)
                .toReversed()
                .map((certif: any, index: number) => (
                  <CertifBlockOverview
                    key={index}
                    attempts={certif.attempt_count}
                    certifDate={certif.dt_created}
                    mainText={certif.certification_name}
                    remarkText={certif.duration ? `${certif.duration} min` : ""}
                    topLeftTitle={certif.sprint_name}
                  />
                ))
            ) : (
              <p>{translate(language, "PAGES.USER.CERTIFICATION.NO_CERTIFICATION")}</p>
            )
          ) : (
            [...Array(5)].map((_, index) => <CertifBlockOverview key={`certif-overview-${index}`} mock={true} />)
          )}
        </div>
      </Col>
    </Row>
  );
};

export default UserOverview;
