import { useEffect, useState } from "react";
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import { useSession } from "../../contexts/SessionContext";
import ApiService from "../../services/ApiService";
import { PermissionComponent } from "../../services/PermissionGuard";
import translate from "../../services/Translate";
import { searchFromDate } from "./Insights.function";

const Insights = () => {
  const { loaded, language } = useSession();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [scheduledCount, setScheduledCount] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0,
  });
  const [chosenDateCount, setChosenDateCount] = useState(0);
  const [chosenDateLoaded, setChosenDateLoaded] = useState(null);

  useEffect(() => {
    ApiService.get("insights/users")
      .then((resp) => setScheduledCount(resp.data.users_insights.active_users))
      .catch((err) => console.error(err))
      .finally(() => setDataLoaded(true));
  }, []);

  return (
    <div className="p-md-5 p-3">
      <h1 className="mb-3">{translate(language, "PAGES.INSIGHTS.TITLE")}</h1>
      <p className="mb-3 mb-md-5">{translate(language, "PAGES.INSIGHTS.DESCRIPTION")}</p>

      <Row>
        <Col lg={3} sm={6} className="text-center mb-3 mb-lg-5">
          <div className="p-3 px-xl-5 border">
            <p className="m-regular border border-1 border-white">
              {translate(language, "PAGES.INSIGHTS.STEPS_TITLE.DAILY")}
            </p>
            {dataLoaded ? <p className="h1">{scheduledCount.daily}</p> : <Spinner />}
          </div>
        </Col>
        <Col lg={3} sm={6} className="text-center mb-3 mb-lg-5">
          <div className="p-3 px-xl-5 border">
            <p className="m-regular border border-1 border-white">
              {translate(language, "PAGES.INSIGHTS.STEPS_TITLE.WEEKLY")}
            </p>
            {dataLoaded ? <p className="h1">{scheduledCount.weekly}</p> : <Spinner />}
          </div>
        </Col>
        <Col lg={3} sm={6} className="text-center mb-3 mb-lg-5">
          <div className="p-3 px-xl-5 border">
            <p className="m-regular border border-1 border-white">
              {translate(language, "PAGES.INSIGHTS.STEPS_TITLE.MONTHLY")}
            </p>
            {dataLoaded ? <p className="h1">{scheduledCount.monthly}</p> : <Spinner />}
          </div>
        </Col>
        {loaded && (
          <Col lg={3} sm={6} className="text-center mb-3 mb-lg-5">
            <PermissionComponent
              neededPermission={[{ methods: ["GET"], route: "users/active" }]}
              showFallBack
              fallBackCustomClass={["h-100"]}
            >
              <div className="p-3 px-xl-5 border">
                <React.Fragment>
                  <p className="m-regular">
                    <input
                      id="calendar-input"
                      type="date"
                      className="border border-1 border-dark px-3"
                      onChange={(event) => {
                        if (event.target.value) {
                          searchFromDate(event.target.value, setChosenDateCount, setChosenDateLoaded);
                        }
                      }}
                    />
                  </p>
                  {chosenDateLoaded ? (
                    <p className="h1">{chosenDateCount}</p>
                  ) : (
                    <p className="h1">
                      <Spinner style={{ opacity: +(chosenDateLoaded !== null) }} />
                    </p>
                  )}
                </React.Fragment>
              </div>
            </PermissionComponent>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Insights;
