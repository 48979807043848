import Papa from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstTable from "../../components/DstTable/DstTable";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { MeetingImportFile, MeetingImportStructuredData } from "./MeetingImport.d";
import { createImportedMeetings } from "./MeetingImport.function";
import "./MeetingImport.scss";

export const MeetingImport = () => {
  const { language } = useSession();
  const [csvData, setCsvData] = useState<Array<MeetingImportStructuredData>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleFileUpload = useCallback(
    (file: File) => {
      Papa.parse<MeetingImportFile>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results: Papa.ParseResult<MeetingImportFile>) => {
          if (results.errors.length > 0) {
            console.error("CSV Parse Errors:", results.errors);
            alert(translate(language, "PAGES.MEETING_IMPORT.ERROR_CSV_IMPORT"));
            return;
          }

          const transformedData = results.data.map((row: MeetingImportFile) => ({
            cohort_id: row.cohort_id,
            meeting_type_id: row.meeting_type_id,
            meeting_id: row.meeting_id_opt,
            name: row.meeting_name,
            custom_meeting_link: row.custom_meeting_link_opt,
            generate_zoom_link: row.generate_zoom_link === "yes",
            date: row.date,
            start: row.start,
            organizer: row.organizer_id,
            visibility: row.visibility,
            animator: row.animator_id_opt,
            duration: row.duration_min,
          }));

          setCsvData(transformedData);
        },
        error: (error: Error) => {
          console.error("Error parsing CSV:", error);
          alert(`${translate(language, "PAGES.MEETING_IMPORT.ERROR_CSV_IMPORT")} ${error.message}`);
        },
      });
    },
    [language]
  );

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      const file = event.dataTransfer?.files[0];
      if (file) {
        handleFileUpload(file);
      }
    },
    [handleFileUpload]
  );

  useEffect(() => {
    const dropZone = dropZoneRef.current;

    if (dropZone) {
      const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      };

      const handleDragLeave = () => {
        dropZone.classList.remove("drag-over");
      };

      dropZone.addEventListener("dragover", handleDragOver);
      dropZone.addEventListener("dragleave", handleDragLeave);
      dropZone.addEventListener("drop", handleDrop);

      return () => {
        dropZone.removeEventListener("dragover", handleDragOver);
        dropZone.removeEventListener("dragleave", handleDragLeave);
        dropZone.removeEventListener("drop", handleDrop);
      };
    }
  }, [handleDrop]);

  const columns = [
    {
      name: translate(language, "PAGES.MEETING_IMPORT.COHORT_ID"),
      key: "cohort_id",
      render: (line: MeetingImportStructuredData) =>
        line.cohort_id
          ?.split(",")
          .map((cohort_id: string) => <Badge className="me-2 xxs-regular">{cohort_id.trim()}</Badge>),
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.MEETING_TYPE_ID"),
      key: "meeting_type_id",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.MEETING_ID"),
      key: "meeting_id",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.NAME"),
      key: "name",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.CUSTOM_MEETING_LINK"),
      key: "custom_meeting_link",
      render: (line: MeetingImportStructuredData) =>
        line.custom_meeting_link ||
        translate(
          language,
          line.generate_zoom_link ? "PAGES.MEETING_IMPORT.AUTO_ZOOM_LINK" : "PAGES.MEETING_IMPORT.NO_ZOOM_LINK"
        ),
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.DATE"),
      key: "date",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.START"),
      key: "start",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.DURATION"),
      key: "duration",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.ORGANIZER"),
      key: "organizer",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.VISIBILITY"),
      key: "visibility",
    },
    {
      name: translate(language, "PAGES.MEETING_IMPORT.ANIMATORS"),
      key: "animator",
      render: (line: MeetingImportStructuredData) =>
        line.animator
          ?.split(",")
          .map((animator: string) => <Badge className="me-2 xxs-regular">{animator.trim()}</Badge>),
    },
  ];

  return (
    <div className="p-3" id="meeting-importer-container">
      <Row className="mb-4">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex ">
              <i
                className="dst-icon-chevron-left p-2 megium-gray-background rounded me-2"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => completeNavigate(event, navigate, "/meetings")}
              />
              <h2 className="mb-0">{translate(language, "PAGES.MEETING_IMPORT.TITLE")}</h2>
            </div>
            <div className="d-flex gap-2">
              {csvData.length > 0 && (
                <DstButton
                  btnImageBefore={<span className="dst-icon-upload-03 me-2" />}
                  value={translate(language, "PAGES.MEETING_IMPORT.NEW_CSV_IMPORT")}
                  variant="light"
                  clickFunction={() => document.getElementById("fileInput")?.click()}
                />
              )}
              <DstButton
                btnImageBefore={<span className="dst-icon-download-02 me-2" />}
                value={translate(language, "PAGES.MEETING_IMPORT.DOWNLOAD_TEMPLATE")}
                variant="light"
                clickFunction={() => {
                  const link = document.createElement("a");
                  link.href = "/share/meeting_template.csv";
                  link.download = "import_meeting_template.csv";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              />
              <DstButton
                btnImageAfter={<span className="dst-icon-arrow-right ms-2" />}
                value={translate(language, "PAGES.MEETING_IMPORT.CREATE_MEETINGS")}
                variant="primary"
                loading={submitting}
                disabled={submitting}
                clickFunction={() => createImportedMeetings(csvData, language, setSubmitting)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {csvData.length === 0 ? (
        <div className="d-flex flex-column gap-2 clickable">
          <DstTable data={csvData} columns={columns} itemsPerPage={20} />
          <div
            ref={dropZoneRef}
            className="border-2 border-dashed rounded-3 p-5 text-center d-flex flex-column align-items-center"
          >
            <div className="text-center s-regular d-flex align-items-center">
              {translate(language, "PAGES.MEETING_IMPORT.DROP_FILE")}
              <DstButton
                value={translate(language, "PAGES.MEETING_IMPORT.LOAD_LOCAL_FILE")}
                variant="link"
                btnClass="ps-1"
                clickFunction={() => document.getElementById("fileInput")?.click()}
              />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <DstTable data={csvData} columns={columns} itemsPerPage={20} classTable="xs-bold" />
        </React.Fragment>
      )}
      <input type="file" id="fileInput" className="d-none" onChange={handleFileInputChange} />
    </div>
  );
};

export default MeetingImport;
