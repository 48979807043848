import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstTabs from "../../components/DstTabs/DstTabs";
import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { fetchMeeting } from "./Meeting.function";
import ManageMeeting from "./components/ManageMeeting/ManageMeeting";

const _formatMeetingTime = (start: string, end: string, language: string): string => {
  const startDate = new Date(start);
  const formattedDate = formatDateHumanly(language, startDate.toISOString(), false);

  const startTime = startDate.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" });
  const endTime = new Date(end).toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" });

  return `${formattedDate} ${startTime} - ${endTime}`;
};

const Meeting = () => {
  const navigate = useNavigate();
  const { meeting_id } = useParams<{ meeting_id: string }>();
  const [meeting, setMeeting] = useState<any | null>(null);
  const { language } = useSession();
  const [activeKey, setActiveKey] = useState<string>("manage_meeting");
  const [loaded, setLoaded] = useState(false);

  const tabsData = [
    {
      id: "manage_meeting",
      title: (
        <React.Fragment>
          <i className="dst-icon-settings-04 me-2" />
          {translate(language, "PAGES.MEETING.MEETING_TABS.MANAGE_MEETING")}
        </React.Fragment>
      ),
      content: <ManageMeeting meeting={meeting} />,
    },
    {
      id: "attendance",
      title: (
        <React.Fragment>
          <i className="dst-icon-clipboard-check me-2" />
          {translate(language, "PAGES.MEETING.MEETING_TABS.ATTENDANCE")}
        </React.Fragment>
      ),
      content: <p>WIP</p>,
    },
  ];

  useEffect(() => {
    if (meeting_id) {
      fetchMeeting(meeting_id, language, setMeeting, setLoaded);
    }
  }, [meeting_id, language]);

  if (!loaded) {
    return (
      <div className="container-fluid mt-3 mb-5">
        <Placeholder animation="glow" className="d-flex align-items-center mb-3">
          <Placeholder as="h2" xs={1} className="me-2" />
          <Placeholder as="h2" xs={4} />
        </Placeholder>
        <DstTabs
          tabsData={tabsData}
          variant="primary"
          activeKey={activeKey}
          onSelect={(key) => {
            if (key) {
              setActiveKey(key);
            }
          }}
          mock
        />
      </div>
    );
  }
  if (!meeting) {
    return (
      <div className="content d-flex justify-content-center align-items-center flex-column">
        <h2>{translate(language, "PAGES.MEETING.NOT_FOUND")}</h2>
        <DstButton
          btnClass="mt-3"
          value={translate(language, "PAGES.MEETING.ALL_MEETINGS")}
          clickFunction={() => navigate("/meetings")}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid mt-3 mb-5">
      <div className="d-flex align-items-center mb-3">
        <i
          className="dst-icon-chevron-left p-2 megium-gray-background rounded me-2 clickable"
          onClick={(event: any) => completeNavigate(event, navigate, "/meetings")}
        />
        <h1 className="mb-0 h3">
          {meeting.meeting_name}
          <span className="dark-gray-font mx-2">{_formatMeetingTime(meeting.start, meeting.end, language)}</span>
        </h1>
      </div>
      <DstTabs
        tabsData={tabsData}
        variant="primary"
        activeKey={activeKey}
        onSelect={(key) => {
          if (key) {
            setActiveKey(key);
          }
        }}
      />
    </div>
  );
};

export default Meeting;
