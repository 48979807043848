import React from "react";
import { Placeholder, Tab, Tabs } from "react-bootstrap";

import { DstTabsProps } from "../../models/TabModel";
import "./DstTabs.scss";

const DstTabs = ({ tabsData, variant, classes, activeKey, onSelect, mock = false }: DstTabsProps) => {
  return (
    <div className={`d-block ${classes}`}>
      <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onSelect={onSelect} className={`mb-3 ${variant}`}>
        {tabsData.map((tab) => (
          <Tab eventKey={tab.id} title={tab.title} key={tab.id}>
            {mock ? (
              <div className="p-3">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={12} className="mb-2" />
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
            ) : (
              tab.content
            )}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default DstTabs;
