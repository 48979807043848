import React, { useEffect, useState } from "react";
import { OverlayTrigger, Placeholder, Popover, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import { PermissionComponent } from "../../../../services/PermissionGuard";
import { formatMinutesHourly, getAgeHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { completeNavigate } from "../../../../services/UtilityService";
import AddUsersToOtherCohortPopup from "./AddUsersToOtherCohortPopup/AddUsersToOtherCohortPopup";
import CohortPartnerDiplomaPopup from "./CohortPartnerDiplomaPopup/CohortPartnerDiplomaPopup";
import { fetchUsers, fetchUsersLogtime, getUserActive, styleUserRow, updateUsers } from "./CohortUsers.function";
import "./CohortUsers.scss";
import CohortUserEvaluationAttemptsPopup from "./CohortUsersEvaluationAttemptPopup/CohortUsersEvaluationAttemptPopup";
import CohortUsersPopup from "./CohortUsersPopup/CohortUsersPopup";
import { CohortUsersProgressReportPopup } from "./CohortUsersProgressReportPopup/CohortUsersProgressReportPopup";

const CohortUsers = ({
  cohortId,
  language,
  isCohortPartner,
}: {
  cohortId: string;
  language: string;
  isCohortPartner: boolean;
}) => {
  const navigate = useNavigate();
  const [cohortUsers, setCohortUsers] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingLogtime, setLoadingLogtime] = useState(false);
  const [usersSelected, setUsersSelected] = useState<any[]>([]);
  const [openPartnerDiplomaModal, setOpenPartnerDiplomaModal] = useState(false);
  const [openAddUsersModal, setOpenAddUsersModal] = useState(false);
  const [openEvaluationInvitationModal, setOpenEvaluationInvitationModal] = useState(false);
  const [openAddUsersToOtherCohortModal, setOpenAddUsersToOtherCohortModal] = useState(false);
  const [openDownloadProgressReportModal, setOpenDownloadProgressReportModal] = useState(false);
  const columns = [
    { name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.LAST_NAME"), key: "last_name" },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.FIRST_NAME"),
      key: "first_name",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.BADGES"),
      key: "flags",
      render: (elem: any) => {
        const allFlags = [
          elem.has_extra_time && { icon_name: "clock-stopwatch", name: "Extra Time" },
          ...(elem.flags || []),
        ].filter(Boolean);

        if (allFlags.length === 0) {
          return "N/A";
        }

        const visibleFlags = allFlags.slice(0, 3);
        const remainingFlags = allFlags.slice(3);

        const popover = (
          <Popover id={`popover-flags-${elem.id}`}>
            <Popover.Body>
              {remainingFlags.map((flag, index) => (
                <div key={`flag-${index}`} className="d-flex align-items-center">
                  <i className={`dst-icon-${flag.icon_name} me-2 blue-font`}></i>
                  <span>{flag.name}</span>
                </div>
              ))}
            </Popover.Body>
          </Popover>
        );
        return (
          <span>
            {visibleFlags.map((flag, index) => (
              <OverlayTrigger
                key={`flag-${index}`}
                trigger={["hover", "focus"]}
                placement="top"
                overlay={<Tooltip id={`tooltip-${flag.name}`}>{flag.name}</Tooltip>}
              >
                <i className={`dst-icon-${flag.icon_name} me-2 blue-font`}></i>
              </OverlayTrigger>
            ))}
            {remainingFlags.length > 0 && (
              <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={popover}>
                <span className="rounded light-blue-background blue-font p-1">+{remainingFlags.length}</span>
              </OverlayTrigger>
            )}
          </span>
        );
      },
    },
    { name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.EMAIL"), key: "email" },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.MANDATORY_EXAMS"),
      key: "validated_mandatory_exams",
      render: (elem: any) => elem.validated_mandatory_exams || "N/A",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.MODULES"),
      key: "validated_modules",
      render: (elem: any) => elem.validated_modules || "N/A",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.ACTIVE_TIME"),
      key: "total_time",
      render: (elem: any) =>
        elem.total_time !== undefined ? formatMinutesHourly(elem.total_time) : "Waiting to fetch",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.LAST_ACTIVITY"),
      key: "dt_last_seen",
      render: (elem: any) =>
        elem.dt_last_seen ? getAgeHumanly(language, new Date(elem.dt_last_seen).getTime()) : "N/A",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.ACTIVE"),
      key: "active",
      render: (elem: any) => getUserActive(elem, language),
    },
  ];
  const preProcessedData = cohortUsers.map((item: any) => ({ ...item, columns }));

  useEffect(() => {
    fetchUsers(cohortId, setCohortUsers, language, () => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
          <Placeholder xs={12} className="mb-3" style={{ height: 20 }} />
          <Placeholder xs={12} className="mb-3" style={{ height: 20 }} />
          <Placeholder xs={12} className="mb-3" style={{ height: 20 }} />
          <Placeholder xs={12} className="mb-3" style={{ height: 20 }} />
        </div>
      </Placeholder>
    );
  }

  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="align-content-center mb-0">{translate(language, "PAGES.COHORT.USERS.TITLE")}</h2>
          <h5 className="ms-2 me-3 align-content-center mb-0">( {!loading && cohortUsers.length} )</h5>
          <DstTableSearch
            data={preProcessedData}
            searchableColumns={["lastName", "firstName", "email", "active"]}
            onSearch={setFilteredData}
          />
        </div>
        <div className="d-flex">
          {usersSelected.length > 0 ? (
            <React.Fragment>
              <DstButton
                btnImageBefore={<span className="dst-icon-pen-tool-plus me-2 align-text-bottom" />}
                value={translate(language, "PAGES.COHORT.USERS.BUTTONS.ADD_TO_OTHER_COHORT")}
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => setOpenAddUsersToOtherCohortModal(true)}
              />
              <DstButton
                btnImageBefore={<i className="dst-icon-user-minus-02 me-2" />}
                value={translate(language, "PAGES.COHORT.USERS.BUTTONS.REMOVE_USERS")}
                variant="danger"
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => {
                  setLoading(true);

                  updateUsers(
                    cohortUsers
                      .filter((user) => !usersSelected.map((item) => item.id).includes(user.id))
                      .map((user) => user.id),
                    false,
                    cohortId,
                    language,
                    () => {
                      fetchUsers(cohortId, setCohortUsers, language, () => {
                        setLoading(false);
                        setUsersSelected([]);
                      });
                    }
                  );
                }}
              />
              <DstButton
                btnImageBefore={<span className="dst-icon-pen-tool-plus me-2 align-text-bottom" />}
                value={translate(language, "PAGES.COHORT.USERS.BUTTONS.EVALUATION_INVITE")}
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => setOpenEvaluationInvitationModal(true)}
              />
              <DstButton
                btnImageBefore={<span className="dst-icon-file-download-02 me-2 align-text-bottom" />}
                value={translate(language, "PAGES.COHORT.USERS.BUTTONS.PROGRESS_REPORT")}
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => setOpenDownloadProgressReportModal(true)}
              />
              {isCohortPartner && (
                <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/cohorts/*/partners/diplomas" }]}>
                  <DstButton
                    btnImageBefore={<i className="dst-icon-certificate-01 me-2" />}
                    value={translate(language, "PAGES.COHORT.USERS.BUTTONS.PARTNER_DIPLOMA_GENERATE")}
                    btnClass="me-2 d-flex align-items-center"
                    clickFunction={() => setOpenPartnerDiplomaModal(true)}
                  />
                </PermissionComponent>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DstButton
                btnImageBefore={<i className="dst-icon-user-plus-02 me-2" />}
                value={translate(language, "PAGES.COHORT.USERS.BUTTONS.ADD_USERS")}
                variant="primary"
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => setOpenAddUsersModal(true)}
              />
              <DstButton
                btnImageBefore={<i className="dst-icon-copy-03 me-2" />}
                value={translate(language, "PAGES.COHORT.USERS.COPY_ALL_EMAILS")}
                variant="light"
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() => {
                  navigator.clipboard.writeText(cohortUsers.map((user) => user.email).join(", ")).catch((err) => {
                    console.error("Failed to copy emails: ", err);
                  });
                }}
              />
              <DstButton
                btnImageBefore={<i className="dst-icon-download-03 me-2" />}
                value={translate(language, "PAGES.COHORT.USERS.FETCH_LOGTIME")}
                variant="light"
                loading={loadingLogtime}
                btnClass="me-2 d-flex align-items-center"
                clickFunction={() =>
                  fetchUsersLogtime(cohortId, cohortUsers, setCohortUsers, language, setLoadingLogtime, setFilteredData)
                }
              />
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={
                  <Popover id="color-code-popover">
                    <Popover.Body className="s-bold">
                      <p className="d-flex align-items-center">
                        <div className="bg-primary-subtle color-circle me-2"></div> VIP
                      </p>
                      <p className="d-flex align-items-center mb-0">
                        <div className="bg-warning-subtle color-circle me-2"></div> Complicated
                      </p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <DstButton
                    variant="light"
                    btnClass="h-100 d-flex align-items-center"
                    value={translate(language, "PAGES.COHORT.USERS.COLOR_CODE")}
                    btnImageBefore={<i className="dst-icon-help-circle me-2"></i>}
                  />
                </div>
              </OverlayTrigger>
            </React.Fragment>
          )}
        </div>
      </div>
      <DstTable
        data={filteredData}
        columns={columns}
        onRowClick={(event, item) => completeNavigate(event, navigate, `/user/${item.id}`)}
        classLine={(elem) => styleUserRow(elem)}
        striped={false}
        selectable
        selectedItems={usersSelected}
        onRowSelect={(selectedItems) => setUsersSelected(selectedItems)}
      />
      <CohortUsersPopup
        language={language}
        openAddUsersModal={openAddUsersModal}
        setOpenAddUsersModal={setOpenAddUsersModal}
        actualUserIds={cohortUsers.map((user) => user.id)}
        cohortId={cohortId}
        setLoading={setLoading}
        setCohortUsers={setCohortUsers}
      />
      <AddUsersToOtherCohortPopup
        language={language}
        openAddUsersToOtherCohortModal={openAddUsersToOtherCohortModal}
        setOpenAddUsersToOtherCohortModal={setOpenAddUsersToOtherCohortModal}
        usersToAddToCohort={usersSelected.map((user) => user.id)}
        setUsersSelected={setUsersSelected}
      />
      <CohortPartnerDiplomaPopup
        language={language}
        cohortId={cohortId}
        userIds={usersSelected.map((user) => user.id)}
        openPartnerDiplomaModal={openPartnerDiplomaModal}
        setOpenPartnerDiplomaModal={setOpenPartnerDiplomaModal}
      />
      <CohortUserEvaluationAttemptsPopup
        language={language}
        cohortId={cohortId}
        userIds={usersSelected.map((user) => user.id)}
        openPopup={openEvaluationInvitationModal}
        setOpenPopup={setOpenEvaluationInvitationModal}
      />
      <CohortUsersProgressReportPopup
        language={language}
        cohortId={cohortId}
        userIds={usersSelected.map((user) => user.id)}
        openPopup={openDownloadProgressReportModal}
        setOpenPopup={setOpenDownloadProgressReportModal}
      />
    </div>
  );
};

export default CohortUsers;
